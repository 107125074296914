<template>
  <div>
    <template
      v-for="(insurance,key) in insurances"
      class="mt-2"
    >
      <div :key="key">
        <b-table-simple
          hover
          caption-top
          caption-bottom
          responsive
        >
          <b-thead>
            <b-tr>
              <b-th
                colspan="9"
              >
                <div class="text-primary font-medium-4 font-weight-bolder">
                  {{ insurance.brand }}
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="align-middle">
                Danışman
              </b-th>
              <b-th class="text-center align-middle">
                Araç Satışı
              </b-th>
              <b-th class="text-center align-middle">
                Trafik
              </b-th>
              <b-th class="text-center align-middle">
                Kasko
              </b-th>
              <b-th class="text-center align-middle">
                Marka Kasko
              </b-th>
              <b-th class="text-center align-middle">
                Hedef Dışı
              </b-th>
              <b-th class="text-center align-middle">
                Trafik Oranı
              </b-th>
              <b-th class="text-center align-middle">
                Kasko Oranı
              </b-th>
              <b-th class="text-center align-middle">
                Marka Kasko Oranı
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(user,uKey) in insurance.users"
              :key="uKey"
            >
              <b-td>{{ user.username }}</b-td>
              <b-td class="text-center width-50">
                {{ user.sales }}
              </b-td>
              <b-td class="text-center width-50">
                {{ user.trafik }}
              </b-td>
              <b-td class="text-center width-50">
                {{ user.kasko }}
              </b-td>
              <b-td class="text-center width-50">
                {{ user.marka_kasko }}
              </b-td>
              <b-td class="text-center width-50">
                {{ user.hedef_disi }}
              </b-td>
              <b-td class="text-center width-50">
                % {{ user.trafik_percent }}
              </b-td>
              <b-td class="text-center width-50">
                % {{ user.kasko_percent }}
              </b-td>
              <b-td class="text-center width-50">
                % {{ user.marka_kasko_percent }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-th>Toplam</b-th>
              <b-th class="text-center">
                {{ insurance.totals.sales }}
              </b-th>
              <b-th class="text-center">
                {{ insurance.totals.trafik }}
              </b-th>
              <b-th class="text-center">
                {{ insurance.totals.kasko }}
              </b-th>
              <b-th class="text-center">
                {{ insurance.totals.marka_kasko }}
              </b-th>
              <b-th class="text-center">
                {{ insurance.totals.hedef_disi }}
              </b-th>
              <b-th class="text-center">
                % {{ insurance.totals.trafik_percent }}
              </b-th>
              <b-th class="text-center">
                % {{ insurance.totals.kasko_percent }}
              </b-th>
              <b-th class="text-center">
                % {{ insurance.totals.marka_kasko_percent }}
              </b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
        <hr>
      </div>
    </template>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Insurances',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
  },
  computed: {
    insurances() {
      return this.$store.getters['salesGpr/getInsurances']
    },
  },
}
</script>
