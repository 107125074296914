<template>
  <div>
    <Loading v-if="loading" />
    <template
      v-for="(sale,key) in sales"
      class="mt-2"
    >
      <div :key="key">
        <b-table-simple
          hover
          caption-top
          caption-bottom
          responsive
          class="bordered border-1 border-light"
        >
          <b-thead>
            <b-tr>
              <b-th
                colspan="10"
              >
                <div class="text-primary font-medium-4 font-weight-bolder">
                  {{ sale.brand }}
                </div>
                <div class="font-medium-1">
                  <span class="text-danger mr-2">Toplam Hedef: {{ sale.totals.goals }}</span>
                  <span class="text-success">Toplam Satış: {{ sale.totals.sales }}</span>
                </div>
                <div
                  v-if="sale.totals.goals"
                  class="mt-1 mb-1"
                >
                  <b-progress
                    :max="sale.totals.goals"
                    class="progress-bar-primary"
                  >
                    <b-progress-bar
                      :value="sale.totals.sales"
                      :label="`${sale.totals.sale_percent}%`"
                      variant="primary"
                    />
                  </b-progress>
                </div>
              </b-th>
            </b-tr>
            <b-tr>
              <b-th class="align-middle">
                Danışman
              </b-th>
              <b-th class="align-middle text-center">
                Fatura
              </b-th>
              <b-th class="align-middle text-center">
                Bağlantı
              </b-th>
              <b-th class="align-middle text-center">
                <div>Toplam</div>
                <div class="font-small-1 text-danger">
                  Fatura + Bağlantı
                </div>
              </b-th>
              <b-th class="align-middle text-center">
                Hedef
              </b-th>
              <b-th class="align-middle text-center">
                Satış Gerçekleşme
              </b-th>
              <b-th class="align-middle text-center">
                Dijital Satış
              </b-th>
              <b-th class="align-middle text-center">
                Referans Satış
              </b-th>
              <b-th class="align-middle text-center">
                Test Sürüşü
              </b-th>
              <b-th class="align-middle text-center">
                Testten Satış
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(user,uKey) in sale.users"
              :key="uKey"
            >
              <template v-if="user.id > 0">
                <b-td>
                  <div class="d-flex align-items-center">
                    <div>
                      <b-button
                        variant="flat-success"
                        pill
                        class="btn-icon mr-1"
                        @click="getUserSalesDetail(sale.id_com_brand,user.id,user.username)"
                      >
                        <FeatherIcon icon="EyeIcon" />
                      </b-button>
                    </div>
                    <div>
                      {{ user.username }}
                    </div>
                  </div>
                </b-td>
                <b-td class="text-center">
                  {{ user.sales }}
                </b-td>
                <b-td class="text-center">
                  {{ user.connected }}
                </b-td>
                <b-td class="text-center">
                  {{ Number(user.sales) + Number(user.connected) }}
                </b-td>
                <b-td class="text-center">
                  {{ user.goals }}
                </b-td>
                <b-td class="text-center">
                  %{{ user.sale_percent }}
                </b-td>
                <b-td class="text-center">
                  {{ user.sales_digital }}
                </b-td>
                <b-td class="text-center">
                  {{ user.sales_reference }}
                </b-td>
                <b-td class="text-center">
                  {{ user.count_testdrive }}
                </b-td>
                <b-td class="text-center">
                  {{ user.sales_testdrive }}
                </b-td>
              </template>
              <template v-else>
                <b-td class="font-weight-bold text-primary">
                  {{ user.username }}
                </b-td>
                <b-td class="text-center font-weight-bold text-primary">
                  {{ user.sales }}
                </b-td>
                <b-td
                  colspan="8"
                  class="text-center"
                />
              </template>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr>
              <b-th>Toplam</b-th>
              <b-th class="text-center">
                {{ sale.totals.sales }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.connected }}
              </b-th>
              <b-th class="text-center">
                {{ Number(sale.totals.sales) + Number(sale.totals.connected) }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.goals }}
              </b-th>
              <b-th class="text-center">
                %{{ sale.totals.sale_percent }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.sales_digital }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.sales_reference }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.count_testdrive }}
              </b-th>
              <b-th class="text-center">
                {{ sale.totals.sales_testdrive }}
              </b-th>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
        <hr>
      </div>
    </template>
    <b-modal
      v-model="modalStatus"
      :title="modalUser + ' Satış Detayı'"
      size="xl"
      hide-footer
      centered
    >
      <Loading v-if="modalLoading" />
      <b-table-simple
        v-else
        hover
        caption-top
        caption-bottom
        responsive
        small
        class="font-small-2"
      >
        <b-thead>
          <b-tr>
            <b-th class="width-50 text-nowrap">
              FÖY NO
            </b-th>
            <b-th>MÜŞTERİ BİLGİSİ</b-th>
            <b-th>ARAÇ Bilgisi</b-th>
            <b-th class="width-50 text-nowrap">
              FATURA
            </b-th>
            <b-th class="width-50 text-nowrap">
              BAĞLANTI
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,key) in userSalesDetail"
            :key="key"
          >
            <b-td class="width-50 text-nowrap">
              <b-link
                :href="'/sale_orderforms/view/' + item.order_number"
                target="_blank"
                :disabled="!item.invoice_date"
              >
                {{ item.order_number }}
              </b-link>
            </b-td>
            <b-td>
              <div>
                {{ item.customer }}
              </div>
              <div class="font-small-1 text-warning">
                {{ item.company_name }}
              </div>
            </b-td>
            <b-td>
              <div>
                {{ item.brand }} {{ item.model }}
              </div>
              <div class="font-small-1 text-warning">
                {{ item.hardware }}
              </div>
              <div class="font-small-1 text-primary">
                {{ item.chasis }}
              </div>
            </b-td>
            <b-td>
              <div v-if="item.invoice_date">
                <div class="font-weight-bold">
                  {{ moment(item.invoice_date).format('DD.MM.YYYY') }}
                </div>
                <div class="text-warning">
                  {{ item.invoice_no }}
                </div>
              </div>
              <div
                v-else
                class="text-danger"
              >
                BAĞLANTI
              </div>
            </b-td>
            <b-td>
              <div>
                {{ moment(item.created).format('DD.MM.YYYY') }}
              </div>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </b-modal>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr, BProgress, BProgressBar, BButton, BModal, BLink,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'Sales',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BProgress,
    BProgressBar,
    BButton,
    BModal,
    BLink,
    Loading,
  },
  data() {
    return {
      loading: null,
      modalStatus: false,
      modalLoading: false,
      modalUser: '',
    }
  },
  computed: {
    filterParams() {
      return this.$store.getters['salesGpr/filterParams']
    },
    userSalesDetail() {
      return this.$store.getters['salesGpr/getUserSalesDetail']
    },
    sales() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.loading = true
      const data = this.$store.getters['salesGpr/getSales']
      if (data.length > 0) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
  methods: {
    getUserSalesDetail(brandID, userID, userName) {
      this.modalUser = userName
      this.modalStatus = true
      this.modalLoading = true
      this.$store.dispatch('salesGpr/userSalesDetail', {
        sdate: this.filterParams.sdate,
        edate: this.filterParams.edate,
        id_com_brand: brandID,
        id_com_user: userID,
      })
        .then(() => {
          this.modalLoading = false
        })
    },
  },
}
</script>
