<template>
  <div class="mt-2">
    <brand-filter :get-filtered-data="getReport" />
    <loading v-if="loadingReport" />
    <template v-else>
      <total-card />
      <div
        v-for="report in reportData"
        :key="report.id"
      >
        <div class="font-weight-bold font-medium-2 my-2 bg-light-primary p-1 rounded">
          {{ report.brand }}
        </div>
        <div class="mb-2">
          <statistics
            :statistics-data="report.statistics"
          />
        </div>
        <stock-table
          :models-data="report.models"
          :totals-data="report.totals"
        />
      </div>
    </template>
  </div>
</template>

<script>
import BrandFilter from '@/views/Reports/Sales/SalesGPR/StockList/BrandFilter.vue'
import Statistics from '@/views/Reports/Sales/SalesGPR/StockList/Statistics.vue'
import StockTable from '@/views/Reports/Sales/SalesGPR/StockList/Table.vue'
import TotalCard from '@/views/Reports/Sales/SalesGPR/StockList/TotalCard.vue'
import Loading from '@/views/Reports/Sales/SalesGPR/StockList/LoadingReport.vue'

export default {
  name: 'StockList',
  components: {
    BrandFilter,
    Statistics,
    StockTable,
    TotalCard,
    Loading,
  },
  computed: {
    reportData() {
      return this.$store.getters['salesStocksReport/getReport']
    },
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
    loadingReport() {
      return this.$store.getters['salesStocksReport/getLoading']
    },
  },
  created() {
    this.getReport()
  },
  methods: {
    getReport() {
      this.$store.dispatch('salesStocksReport/report', this.filterData)
    },
  },
}
</script>
