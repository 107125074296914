<template>
  <b-table-simple
    class="m-0 p-0"
    hover
    caption-top
    responsive
    small
  >
    <b-thead>
      <b-tr class="bg-gradient-primary">
        <b-th
          class="font-small-1 text-nowrap align-middle bg-transparent"
        >
          ŞASE
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          ARAÇ CİNSİ
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          MODEL YILI
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          RENK
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          GÜN
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          DURUM
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          BAĞLANTI
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          FATURA
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          VADE
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          VALÖR
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          STOK DURUM
        </b-th>
        <b-th class="font-small-1 text-nowrap align-middle bg-transparent">
          KONUM
        </b-th>
      </b-tr>
    </b-thead>
    <b-tbody>
      <template v-for="(detail,index) in detailData">
        <template v-if="!filterData.connected_status">
          <b-tr
            v-if="detail.connected_text !== 'Var'"
            :key="index"
            :class="'bg-gradient-' + detail.rowClass"
          >
            <b-td class="font-small-1 text-light">
              {{ detail.chasis }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.myear }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.color }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.stock_days }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.status_text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.connected_text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ moment(detail.invoice_date).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ moment(detail.maturity).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.value_date }}
            </b-td>
            <b-td class="font-small-1 text-light">
              <div v-if="detail.id_com_stock">
                {{ detail.stock_status? detail.stock_status : '-' }}
              </div>
              <div v-else>
                Stok İşlenmemiş
              </div>
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.location }}
            </b-td>
          </b-tr>
        </template>
        <template v-else>
          <b-tr
            :key="index"
            :class="'bg-gradient-' + detail.rowClass"
          >
            <b-td class="font-small-1 text-light">
              {{ detail.chasis }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.myear }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.color }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.stock_days }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.status_text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.connected_text }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ moment(detail.invoice_date).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ moment(detail.maturity).format('DD.MM.YYYY') }}
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.value_date }}
            </b-td>
            <b-td class="font-small-1 text-light">
              <div v-if="detail.id_com_stock">
                {{ detail.stock_status? detail.stock_status : '-' }}
              </div>
              <div v-else>
                Stok İşlenmemiş
              </div>
            </b-td>
            <b-td class="font-small-1 text-light">
              {{ detail.location }}
            </b-td>
          </b-tr>
        </template>
      </template>
    </b-tbody>
  </b-table-simple>
</template>

<script>
import {
  BTableSimple, BTbody, BTd, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'DetailTable',
  components: {
    BTableSimple, BTbody, BTd, BTh, BThead, BTr,
  },
  props: {
    detailData: {
      type: Array,
      required: true,
    },
  },
  computed: {
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
  },
}
</script>
