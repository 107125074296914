<template>
  <div>
    <template
      v-for="(testDrive,key) in testDrives"
      class="mt-2"
    >
      <b-table-simple
        :key="'tb_' + key"
        hover
        caption-top
        caption-bottom
        responsive
        class="bordered border-light"
      >
        <b-thead>
          <b-tr>
            <b-th
              colspan="5"
            >
              <div class="text-primary font-medium-4 font-weight-bolder">
                {{ testDrive.brand }}
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th>Danışman</b-th>
            <b-th class="text-center width-100 text-nowrap">
              GÖRÜŞME
            </b-th>
            <b-th class="text-center width-100 text-nowrap">
              TEST SÜRÜŞÜ
            </b-th>
            <b-th class="text-center width-100 text-nowrap">
              HEDEF
            </b-th>
            <b-th class="text-center width-100 text-nowrap">
              HEDEF ORANI
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <template v-for="(user,uKey) in testDrive.users">
            <b-tr
              :key="'user_'+uKey"
            >
              <b-td>
                <div class="d-flex align-items-center">
                  <b-button
                    :variant="!user.detailToggle? 'flat-success' : 'flat-danger'"
                    pill
                    class="btn-icon mr-1"
                    @click="toggleDetail(key,uKey)"
                  >
                    <FeatherIcon :icon="!user.detailToggle? 'EyeIcon' : 'EyeOffIcon'" />
                  </b-button>
                  <div>
                    {{ user.username }}
                  </div>
                </div>
              </b-td>
              <b-td class="text-center width-100 text-nowrap">
                {{ user.interview }}
              </b-td>
              <b-td class="text-center width-100 text-nowrap">
                {{ user.testDrive }}
              </b-td>
              <b-td class="text-center width-100 text-nowrap">
                {{ user.testDrive_goal }}
              </b-td>
              <b-td class="text-center width-100 text-nowrap">
                %{{ user.testDrive_percent }}
              </b-td>
            </b-tr>
            <template v-if="user.detailToggle">
              <b-tr
                :key="'userdth_' + uKey"
                class="font-small-2"
              >
                <b-th>
                  MODEL
                </b-th>
                <b-th class="text-center">
                  GÖRÜŞME
                </b-th>
                <b-th class="text-center">
                  TEST SÜRÜŞÜ
                </b-th>
                <b-th />
                <b-th />
              </b-tr>
              <template v-for="(model,mkey) in user.models">
                <b-tr
                  :key="'userd_' + uKey + '_' + mkey"
                  class="font-weight-bold font-small-2 bg-light"
                  :class="model.testDrive_count === 0? 'bg-light-danger' : 'bg-light-success'"
                >
                  <b-td>
                    {{ model.title }}
                  </b-td>
                  <b-td class="text-center width-100 text-nowrap">
                    {{ model.interview_count }}
                  </b-td>
                  <b-td
                    class="text-center width-100 text-nowrap font-weight-bold"
                  >
                    {{ model.testDrive_count }}
                  </b-td>
                  <b-td class="text-center width-100 text-nowrap">
                    -
                  </b-td>
                  <b-td class="text-center width-100 text-nowrap">
                    -
                  </b-td>
                </b-tr>
              </template>
            </template>
          </template>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th>Toplam</b-th>
            <b-th class="text-center">
              {{ testDrive.totals.interview }}
            </b-th>
            <b-th class="text-center">
              {{ testDrive.totals.testDrive }}
            </b-th>
            <b-th class="text-center">
              {{ testDrive.totals.testDrive_goal }}
            </b-th>
            <b-th class="text-center">
              {{ testDrive.totals.testDrive_percent }}
            </b-th>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <hr :key="key">
    </template>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr, BButton,
} from 'bootstrap-vue'

export default {
  name: 'TestDrives',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
    BButton,
  },
  data() {
    return {
      loading: null,
    }
  },
  computed: {
    testDrives() {
      const data = this.$store.getters['salesGpr/getTestDrives']
      if (data) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.loading = false
      }
      return data
    },
  },
  methods: {
    toggleDetail(brandKey, userKey) {
      this.testDrives[brandKey].users[userKey].detailToggle = !this.testDrives[brandKey].users[userKey].detailToggle
    },
  },
}
</script>
