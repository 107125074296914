<template>
  <b-row class="match-height d-flex align-items-center">
    <b-col
      cols="12"
      md="6"
      class="mb-1"
    >
      <b-input-group>
        <b-form-datepicker
          v-model="filterData.sdate"
          v-bind="{labelNoDateSelected: 'Stok Fatura Tarihi Başlangıç',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append v-if="filterData.sdate">
          <b-button
            variant="warning"
            @click="removeDate('sdate')"
          >
            <FeatherIcon icon="XCircleIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col
      cols="12"
      md="6"
      class="mb-1"
    >
      <b-input-group>
        <b-form-datepicker
          v-model="filterData.edate"
          v-bind="{labelNoDateSelected: 'Stok Fatura Tarihi Bitiş',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
        />
        <b-input-group-append v-if="filterData.edate">
          <b-button
            variant="warning"
            @click="removeDate('edate')"
          >
            <FeatherIcon icon="XCircleIcon" />
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-col>
    <b-col>
      <v-select
        v-model="filterData.id_com_brand"
        :options="brands"
        label="title"
        :reduce="brand => brand.id"
        placeholder="Marka"
      />
    </b-col>
    <b-col>
      <cleave
        id="MODEL_YILI"
        v-model="filterData.MODEL_YILI"
        class="form-control"
        :raw="false"
        :options="options.year"
        placeholder="Model Yılı"
      />
    </b-col>
    <b-col cols="auto">
      <b-form-checkbox
        v-model="filterData.connected_status"
        switch
        @change="getFilteredData"
      >
        Bağlantıları Göster
      </b-form-checkbox>
    </b-col>
    <b-col cols="auto">
      <b-button
        variant="primary"
        @click="getFilteredData"
      >
        <FeatherIcon icon="FilterIcon" />
        Filtrele
      </b-button>
    </b-col>
    <b-col
      v-if="!filterData.connected_status"
      cols="12"
      class="mt-1"
    >
      <b-alert
        show
        variant="warning"
        class="mb-0"
      >
        <div class="alert-body text-center">
          <FeatherIcon icon="InfoIcon" />
          Model detaylarında bağlantı olan araçlar gizlenmiştir.
        </div>
      </b-alert>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BButton, BFormCheckbox, BAlert, BFormDatepicker, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'BrandFilter',
  components: {
    BFormDatepicker,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BAlert,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    Cleave,
  },
  props: {
    getFilteredData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      options: {
        year: {
          blocks: [4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    filterData() {
      return this.$store.getters['salesStocksReport/getFilterData']
    },
  },
  created() {
    this.getBrands()
  },
  methods: {
    removeDate(type) {
      if (type === 'sdate') {
        this.filterData.sdate = null
      }
      if (type === 'edate') {
        this.filterData.edate = null
      }
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
  },
}
</script>
