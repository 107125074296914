<template>
  <div>
    <template
      v-for="(interview,key) in interviews"
      class="mt-2"
    >
      <b-table-simple
        :key="'int_key-' + key"
        hover
        caption-top
        caption-bottom
        responsive
        class="bordered border-light"
      >
        <b-thead>
          <b-tr>
            <b-th
              colspan="8"
            >
              <div class="text-primary font-medium-4 font-weight-bolder">
                {{ interview.brand }}
              </div>
            </b-th>
          </b-tr>
          <b-tr>
            <b-th class="align-middle">
              Danışman
            </b-th>
            <b-th class="align-middle text-center">
              Toplam Görüşme
            </b-th>
            <b-th class="align-middle text-center">
              Telefon
            </b-th>
            <b-th class="align-middle text-center">
              Showroom
            </b-th>
            <b-th class="align-middle text-center">
              Web Distribütör
            </b-th>
            <b-th class="align-middle text-center">
              Web
            </b-th>
            <b-th class="align-middle text-center">
              DİĞER
            </b-th>
            <!--            <b-th class="align-middle text-center">-->
            <!--              Web Hedef-->
            <!--            </b-th>-->
            <!--            <b-th class="align-middle text-center">-->
            <!--              Web Oranı-->
            <!--            </b-th>-->
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(user,uKey) in interview.users"
            :key="uKey"
          >
            <b-td>{{ user.username }}</b-td>
            <b-td class="text-center width-100">
              {{ user.interview }}
            </b-td>
            <b-td class="text-center width-100">
              {{ user.phone }}
            </b-td>
            <b-td class="text-center width-100">
              {{ user.showroom }}
            </b-td>
            <b-td class="text-center width-100">
              {{ user.web_dist }}
            </b-td>
            <b-td class="text-center width-100">
              {{ user.web }}
            </b-td>
            <b-td class="text-center width-100">
              {{ user.others }}
            </b-td>
            <!--            <b-td class="text-center width-100">-->
            <!--              {{ user.web_goal }}-->
            <!--            </b-td>-->
            <!--            <b-td class="text-center width-100">-->
            <!--              {{ user.web_percent }}-->
            <!--            </b-td>-->
          </b-tr>
        </b-tbody>
        <b-tfoot>
          <b-tr>
            <b-th>Toplam</b-th>
            <b-th class="text-center">
              {{ interview.totals.interview | toNumber }}
            </b-th>
            <b-th class="text-center">
              {{ interview.totals.phone | toNumber }}
            </b-th>
            <b-th class="text-center">
              {{ interview.totals.showroom | toNumber }}
            </b-th>
            <b-th class="text-center">
              {{ interview.totals.web_dist | toNumber }}
            </b-th>
            <b-th class="text-center">
              {{ interview.totals.web | toNumber }}
            </b-th>
            <b-th class="text-center">
              {{ interview.totals.others | toNumber }}
            </b-th>
            <!--            <b-th class="text-center">-->
            <!--              {{ interview.totals.web_goal }}-->
            <!--            </b-th>-->
            <!--            <b-th class="text-center">-->
            <!--              {{ interview.totals.web_percent }}-->
            <!--            </b-th>-->
          </b-tr>
        </b-tfoot>
      </b-table-simple>
      <hr :key="key">
    </template>
  </div>
</template>
<script>
import {
  BTableSimple, BTbody, BTd, BTfoot, BTh, BThead, BTr,
} from 'bootstrap-vue'

export default {
  name: 'Interviews',
  components: {
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTbody,
    BTd,
    BTfoot,
  },
  data() {
    return {
      loading: null,
    }
  },
  computed: {
    interviews() {
      return this.$store.getters['salesGpr/getInterviews']
    },
  },
}
</script>
